/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  GenerateCustomerDocumentsParams,
  GenerateDocumentsForCustomerParams,
  GetCustomerDocumentsParams,
  InternalCustomerDocumentDownloadResponse,
  InternalDocumentType,
  InternalDocumentUrlResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getCustomerDocumentPresignedUrl = (
  documentUid: string,
  signal?: AbortSignal
) => {
  return customInstance<InternalDocumentUrlResponse>({
    url: `/operations/statement/customer/document/${documentUid}`,
    method: 'GET',
    signal,
  });
};

export const getGetCustomerDocumentPresignedUrlQueryKey = (
  documentUid: string
) => {
  return [`/operations/statement/customer/document/${documentUid}`] as const;
};

export const getGetCustomerDocumentPresignedUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerDocumentPresignedUrl>>,
  TError = unknown
>(
  documentUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerDocumentPresignedUrl>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCustomerDocumentPresignedUrlQueryKey(documentUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerDocumentPresignedUrl>>
  > = ({ signal }) => getCustomerDocumentPresignedUrl(documentUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!documentUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerDocumentPresignedUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerDocumentPresignedUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerDocumentPresignedUrl>>
>;
export type GetCustomerDocumentPresignedUrlQueryError = unknown;

export const useGetCustomerDocumentPresignedUrl = <
  TData = Awaited<ReturnType<typeof getCustomerDocumentPresignedUrl>>,
  TError = unknown
>(
  documentUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerDocumentPresignedUrl>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerDocumentPresignedUrlQueryOptions(
    documentUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const generateCustomerDocuments = (
  documentType: InternalDocumentType,
  params: GenerateCustomerDocumentsParams
) => {
  return customInstance<void>({
    url: `/operations/statement/customer/documents/${documentType}`,
    method: 'POST',
    params,
  });
};

export const getGenerateCustomerDocumentsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateCustomerDocuments>>,
    TError,
    {
      documentType: InternalDocumentType;
      params: GenerateCustomerDocumentsParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateCustomerDocuments>>,
  TError,
  {
    documentType: InternalDocumentType;
    params: GenerateCustomerDocumentsParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateCustomerDocuments>>,
    {
      documentType: InternalDocumentType;
      params: GenerateCustomerDocumentsParams;
    }
  > = (props) => {
    const { documentType, params } = props ?? {};

    return generateCustomerDocuments(documentType, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateCustomerDocumentsMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateCustomerDocuments>>
>;

export type GenerateCustomerDocumentsMutationError = unknown;

export const useGenerateCustomerDocuments = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateCustomerDocuments>>,
    TError,
    {
      documentType: InternalDocumentType;
      params: GenerateCustomerDocumentsParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateCustomerDocuments>>,
  TError,
  {
    documentType: InternalDocumentType;
    params: GenerateCustomerDocumentsParams;
  },
  TContext
> => {
  const mutationOptions = getGenerateCustomerDocumentsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCustomerDocuments = (
  customerUid: string,
  params?: GetCustomerDocumentsParams,
  signal?: AbortSignal
) => {
  return customInstance<InternalCustomerDocumentDownloadResponse[]>({
    url: `/operations/statement/customer/${customerUid}/documents`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetCustomerDocumentsQueryKey = (
  customerUid: string,
  params?: GetCustomerDocumentsParams
) => {
  return [
    `/operations/statement/customer/${customerUid}/documents`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCustomerDocumentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustomerDocuments>>,
  TError = unknown
>(
  customerUid: string,
  params?: GetCustomerDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerDocuments>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetCustomerDocumentsQueryKey(customerUid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCustomerDocuments>>
  > = ({ signal }) => getCustomerDocuments(customerUid, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!customerUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustomerDocuments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCustomerDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomerDocuments>>
>;
export type GetCustomerDocumentsQueryError = unknown;

export const useGetCustomerDocuments = <
  TData = Awaited<ReturnType<typeof getCustomerDocuments>>,
  TError = unknown
>(
  customerUid: string,
  params?: GetCustomerDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustomerDocuments>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustomerDocumentsQueryOptions(
    customerUid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const generateDocumentsForCustomer = (
  customerUid: string,
  documentType: InternalDocumentType,
  params: GenerateDocumentsForCustomerParams
) => {
  return customInstance<void>({
    url: `/operations/statement/customer/${customerUid}/documents/${documentType}`,
    method: 'POST',
    params,
  });
};

export const getGenerateDocumentsForCustomerMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateDocumentsForCustomer>>,
    TError,
    {
      customerUid: string;
      documentType: InternalDocumentType;
      params: GenerateDocumentsForCustomerParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateDocumentsForCustomer>>,
  TError,
  {
    customerUid: string;
    documentType: InternalDocumentType;
    params: GenerateDocumentsForCustomerParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateDocumentsForCustomer>>,
    {
      customerUid: string;
      documentType: InternalDocumentType;
      params: GenerateDocumentsForCustomerParams;
    }
  > = (props) => {
    const { customerUid, documentType, params } = props ?? {};

    return generateDocumentsForCustomer(customerUid, documentType, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateDocumentsForCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateDocumentsForCustomer>>
>;

export type GenerateDocumentsForCustomerMutationError = unknown;

export const useGenerateDocumentsForCustomer = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateDocumentsForCustomer>>,
    TError,
    {
      customerUid: string;
      documentType: InternalDocumentType;
      params: GenerateDocumentsForCustomerParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateDocumentsForCustomer>>,
  TError,
  {
    customerUid: string;
    documentType: InternalDocumentType;
    params: GenerateDocumentsForCustomerParams;
  },
  TContext
> => {
  const mutationOptions =
    getGenerateDocumentsForCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};
