/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InternalDocumentType =
  typeof InternalDocumentType[keyof typeof InternalDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalDocumentType = {
  MONTHLY_STATEMENT: 'MONTHLY_STATEMENT',
  ANNUAL_TAX_SUMMARY: 'ANNUAL_TAX_SUMMARY',
  PRODUCT_SUMMARY_BOX: 'PRODUCT_SUMMARY_BOX',
} as const;
