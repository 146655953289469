/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BankProductResponse,
  BondsmithPageListableBankProductPaginatedResponse,
  InternalDocumentUrlResponse,
  ListPaginatedProductsParams,
  PartnerBankFscsBrochureRequest,
  PartnerBankFscsBrochureResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getFscsBrochureForBank = (
  bankUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PartnerBankFscsBrochureResponse>({
    url: `/operations/banks/${bankUid}/brochure`,
    method: 'GET',
    signal,
  });
};

export const getGetFscsBrochureForBankQueryKey = (bankUid: string) => {
  return [`/operations/banks/${bankUid}/brochure`] as const;
};

export const getGetFscsBrochureForBankQueryOptions = <
  TData = Awaited<ReturnType<typeof getFscsBrochureForBank>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFscsBrochureForBank>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetFscsBrochureForBankQueryKey(bankUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFscsBrochureForBank>>
  > = ({ signal }) => getFscsBrochureForBank(bankUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bankUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFscsBrochureForBank>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFscsBrochureForBankQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFscsBrochureForBank>>
>;
export type GetFscsBrochureForBankQueryError = unknown;

export const useGetFscsBrochureForBank = <
  TData = Awaited<ReturnType<typeof getFscsBrochureForBank>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFscsBrochureForBank>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFscsBrochureForBankQueryOptions(bankUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateFscsBrochureForBank = (
  bankUid: string,
  partnerBankFscsBrochureRequest: PartnerBankFscsBrochureRequest
) => {
  return customInstance<void>({
    url: `/operations/banks/${bankUid}/brochure`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: partnerBankFscsBrochureRequest,
  });
};

export const getUpdateFscsBrochureForBankMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFscsBrochureForBank>>,
    TError,
    { bankUid: string; data: PartnerBankFscsBrochureRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFscsBrochureForBank>>,
  TError,
  { bankUid: string; data: PartnerBankFscsBrochureRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFscsBrochureForBank>>,
    { bankUid: string; data: PartnerBankFscsBrochureRequest }
  > = (props) => {
    const { bankUid, data } = props ?? {};

    return updateFscsBrochureForBank(bankUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateFscsBrochureForBankMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFscsBrochureForBank>>
>;
export type UpdateFscsBrochureForBankMutationBody =
  PartnerBankFscsBrochureRequest;
export type UpdateFscsBrochureForBankMutationError = unknown;

export const useUpdateFscsBrochureForBank = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFscsBrochureForBank>>,
    TError,
    { bankUid: string; data: PartnerBankFscsBrochureRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateFscsBrochureForBank>>,
  TError,
  { bankUid: string; data: PartnerBankFscsBrochureRequest },
  TContext
> => {
  const mutationOptions = getUpdateFscsBrochureForBankMutationOptions(options);

  return useMutation(mutationOptions);
};
export const calculateAer = (signal?: AbortSignal) => {
  return customInstance<number>({
    url: `/operations/products/calculate-aer`,
    method: 'GET',
    signal,
  });
};

export const getCalculateAerQueryKey = () => {
  return [`/operations/products/calculate-aer`] as const;
};

export const getCalculateAerQueryOptions = <
  TData = Awaited<ReturnType<typeof calculateAer>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof calculateAer>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCalculateAerQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof calculateAer>>> = ({
    signal,
  }) => calculateAer(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof calculateAer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CalculateAerQueryResult = NonNullable<
  Awaited<ReturnType<typeof calculateAer>>
>;
export type CalculateAerQueryError = unknown;

export const useCalculateAer = <
  TData = Awaited<ReturnType<typeof calculateAer>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof calculateAer>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCalculateAerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const synchronizeMaxDeposit = () => {
  return customInstance<void>({
    url: `/operations/products/capacity/synchronize`,
    method: 'POST',
  });
};

export const getSynchronizeMaxDepositMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof synchronizeMaxDeposit>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof synchronizeMaxDeposit>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof synchronizeMaxDeposit>>,
    void
  > = () => {
    return synchronizeMaxDeposit();
  };

  return { mutationFn, ...mutationOptions };
};

export type SynchronizeMaxDepositMutationResult = NonNullable<
  Awaited<ReturnType<typeof synchronizeMaxDeposit>>
>;

export type SynchronizeMaxDepositMutationError = unknown;

export const useSynchronizeMaxDeposit = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof synchronizeMaxDeposit>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof synchronizeMaxDeposit>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getSynchronizeMaxDepositMutationOptions(options);

  return useMutation(mutationOptions);
};
export const generateAllSummaryBoxes = () => {
  return customInstance<void>({
    url: `/operations/products/generateAllSummaryBoxes`,
    method: 'POST',
  });
};

export const getGenerateAllSummaryBoxesMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateAllSummaryBoxes>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateAllSummaryBoxes>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateAllSummaryBoxes>>,
    void
  > = () => {
    return generateAllSummaryBoxes();
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateAllSummaryBoxesMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateAllSummaryBoxes>>
>;

export type GenerateAllSummaryBoxesMutationError = unknown;

export const useGenerateAllSummaryBoxes = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateAllSummaryBoxes>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateAllSummaryBoxes>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = getGenerateAllSummaryBoxesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listPaginatedProducts = (
  params?: ListPaginatedProductsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageListableBankProductPaginatedResponse>({
    url: `/operations/products/paginated`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListPaginatedProductsQueryKey = (
  params?: ListPaginatedProductsParams
) => {
  return [
    `/operations/products/paginated`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListPaginatedProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof listPaginatedProducts>>,
  TError = unknown
>(
  params?: ListPaginatedProductsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPaginatedProducts>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListPaginatedProductsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listPaginatedProducts>>
  > = ({ signal }) => listPaginatedProducts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPaginatedProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPaginatedProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPaginatedProducts>>
>;
export type ListPaginatedProductsQueryError = unknown;

export const useListPaginatedProducts = <
  TData = Awaited<ReturnType<typeof listPaginatedProducts>>,
  TError = unknown
>(
  params?: ListPaginatedProductsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPaginatedProducts>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPaginatedProductsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchProduct = (productUid: string, signal?: AbortSignal) => {
  return customInstance<BankProductResponse>({
    url: `/operations/products/${productUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchProductQueryKey = (productUid: string) => {
  return [`/operations/products/${productUid}`] as const;
};

export const getFetchProductQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchProduct>>,
  TError = unknown
>(
  productUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProduct>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchProductQueryKey(productUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchProduct>>> = ({
    signal,
  }) => fetchProduct(productUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!productUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchProduct>>
>;
export type FetchProductQueryError = unknown;

export const useFetchProduct = <
  TData = Awaited<ReturnType<typeof fetchProduct>>,
  TError = unknown
>(
  productUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProduct>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchProductQueryOptions(productUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const duplicateProduct = (productUid: string) => {
  return customInstance<void>({
    url: `/operations/products/${productUid}/duplicate`,
    method: 'POST',
  });
};

export const getDuplicateProductMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof duplicateProduct>>,
    TError,
    { productUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof duplicateProduct>>,
  TError,
  { productUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof duplicateProduct>>,
    { productUid: string }
  > = (props) => {
    const { productUid } = props ?? {};

    return duplicateProduct(productUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type DuplicateProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof duplicateProduct>>
>;

export type DuplicateProductMutationError = unknown;

export const useDuplicateProduct = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof duplicateProduct>>,
    TError,
    { productUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof duplicateProduct>>,
  TError,
  { productUid: string },
  TContext
> => {
  const mutationOptions = getDuplicateProductMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getSummaryBoxLink = (
  productUid: string,
  platformUid: string,
  signal?: AbortSignal
) => {
  return customInstance<InternalDocumentUrlResponse>({
    url: `/operations/products/${productUid}/platform/${platformUid}/summary`,
    method: 'GET',
    signal,
  });
};

export const getGetSummaryBoxLinkQueryKey = (
  productUid: string,
  platformUid: string
) => {
  return [
    `/operations/products/${productUid}/platform/${platformUid}/summary`,
  ] as const;
};

export const getGetSummaryBoxLinkQueryOptions = <
  TData = Awaited<ReturnType<typeof getSummaryBoxLink>>,
  TError = unknown
>(
  productUid: string,
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSummaryBoxLink>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSummaryBoxLinkQueryKey(productUid, platformUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSummaryBoxLink>>> =
    ({ signal }) => getSummaryBoxLink(productUid, platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(productUid && platformUid),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getSummaryBoxLink>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSummaryBoxLinkQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSummaryBoxLink>>
>;
export type GetSummaryBoxLinkQueryError = unknown;

export const useGetSummaryBoxLink = <
  TData = Awaited<ReturnType<typeof getSummaryBoxLink>>,
  TError = unknown
>(
  productUid: string,
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSummaryBoxLink>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSummaryBoxLinkQueryOptions(
    productUid,
    platformUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const generateSummaryBox = (productUid: string, platformUid: string) => {
  return customInstance<void>({
    url: `/operations/products/${productUid}/platform/${platformUid}/summary`,
    method: 'POST',
  });
};

export const getGenerateSummaryBoxMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateSummaryBox>>,
    TError,
    { productUid: string; platformUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof generateSummaryBox>>,
  TError,
  { productUid: string; platformUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof generateSummaryBox>>,
    { productUid: string; platformUid: string }
  > = (props) => {
    const { productUid, platformUid } = props ?? {};

    return generateSummaryBox(productUid, platformUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type GenerateSummaryBoxMutationResult = NonNullable<
  Awaited<ReturnType<typeof generateSummaryBox>>
>;

export type GenerateSummaryBoxMutationError = unknown;

export const useGenerateSummaryBox = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof generateSummaryBox>>,
    TError,
    { productUid: string; platformUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof generateSummaryBox>>,
  TError,
  { productUid: string; platformUid: string },
  TContext
> => {
  const mutationOptions = getGenerateSummaryBoxMutationOptions(options);

  return useMutation(mutationOptions);
};
