/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  AssignDocumentsRequest,
  BondsmithPageCompanyPaginatedRecord,
  BondsmithPageComplianceInterventionPaginatedRecord,
  BondsmithPageCustomerNominatedAccountChangeResponse,
  BondsmithPagePlatformCustomerScreenReducedResponse,
  BondsmithPagePlatformCustomerScreenResponse,
  BondsmithPagePlatformPersonPaginatedRecord,
  ClientDetailsResponse,
  DocumentReviewRequest,
  ExternalFSMEvent,
  FetchCustomersRequiringInterventionParams,
  FetchDocumentsParams,
  FetchDocumentsResponse,
  FetchPeopleParams,
  GetCompaniesParams,
  InternalDocumentUrlResponse,
  InterventionResultResponse,
  KycDocumentInformationRequest,
  ListCustomersByPlatformParams,
  ListPendingCustomerNominatedAccountChangesParams,
  ListPlatformCustomerDetailsMinimalParams,
  ListPlatformCustomersDetailsParams,
  NominatedAccountRequest,
  PlatformCustomerNoteRequest,
  PlatformCustomerNoteResponse,
  PlatformCustomerResponse,
  RiskResult,
  SavingsExchangeInterventionReceivedPersonIntervention,
  SavingsExchangeInterventionRequiredPersonIntervention,
  UpdatePlatformCustomerVerificationStatusRequest,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const createCasesForPersonsWithoutCase = () => {
  return customInstance<void>({
    url: `/operations/own/customer/person/case`,
    method: 'POST',
  });
};

export const getCreateCasesForPersonsWithoutCaseMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCasesForPersonsWithoutCase>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCasesForPersonsWithoutCase>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCasesForPersonsWithoutCase>>,
    void
  > = () => {
    return createCasesForPersonsWithoutCase();
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCasesForPersonsWithoutCaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCasesForPersonsWithoutCase>>
>;

export type CreateCasesForPersonsWithoutCaseMutationError = unknown;

export const useCreateCasesForPersonsWithoutCase = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCasesForPersonsWithoutCase>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCasesForPersonsWithoutCase>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getCreateCasesForPersonsWithoutCaseMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCompanies = (
  params?: GetCompaniesParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageCompanyPaginatedRecord>({
    url: `/operations/own/customer/platform/companies`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetCompaniesQueryKey = (params?: GetCompaniesParams) => {
  return [
    `/operations/own/customer/platform/companies`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetCompaniesQueryOptions = <
  TData = Awaited<ReturnType<typeof getCompanies>>,
  TError = unknown
>(
  params?: GetCompaniesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanies>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompaniesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompanies>>> = ({
    signal,
  }) => getCompanies(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCompanies>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCompaniesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompanies>>
>;
export type GetCompaniesQueryError = unknown;

export const useGetCompanies = <
  TData = Awaited<ReturnType<typeof getCompanies>>,
  TError = unknown
>(
  params?: GetCompaniesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCompanies>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCompaniesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createNoteForCustomer = (
  platformCustomerNoteRequest: PlatformCustomerNoteRequest
) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/customer/check/audit/notes`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: platformCustomerNoteRequest,
  });
};

export const getCreateNoteForCustomerMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNoteForCustomer>>,
    TError,
    { data: PlatformCustomerNoteRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createNoteForCustomer>>,
  TError,
  { data: PlatformCustomerNoteRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createNoteForCustomer>>,
    { data: PlatformCustomerNoteRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createNoteForCustomer(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateNoteForCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof createNoteForCustomer>>
>;
export type CreateNoteForCustomerMutationBody = PlatformCustomerNoteRequest;
export type CreateNoteForCustomerMutationError = unknown;

export const useCreateNoteForCustomer = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createNoteForCustomer>>,
    TError,
    { data: PlatformCustomerNoteRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createNoteForCustomer>>,
  TError,
  { data: PlatformCustomerNoteRequest },
  TContext
> => {
  const mutationOptions = getCreateNoteForCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPlatformCustomerNotes = (
  customerUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformCustomerNoteResponse[]>({
    url: `/operations/own/customer/platform/customer/check/audit/notes/${customerUid}`,
    method: 'GET',
    signal,
  });
};

export const getGetPlatformCustomerNotesQueryKey = (customerUid: string) => {
  return [
    `/operations/own/customer/platform/customer/check/audit/notes/${customerUid}`,
  ] as const;
};

export const getGetPlatformCustomerNotesQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlatformCustomerNotes>>,
  TError = unknown
>(
  customerUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformCustomerNotes>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPlatformCustomerNotesQueryKey(customerUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlatformCustomerNotes>>
  > = ({ signal }) => getPlatformCustomerNotes(customerUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!customerUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlatformCustomerNotes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlatformCustomerNotesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlatformCustomerNotes>>
>;
export type GetPlatformCustomerNotesQueryError = unknown;

export const useGetPlatformCustomerNotes = <
  TData = Awaited<ReturnType<typeof getPlatformCustomerNotes>>,
  TError = unknown
>(
  customerUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformCustomerNotes>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlatformCustomerNotesQueryOptions(
    customerUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPlatformCustomerCheckAuditRecord = (
  customerUid: string,
  signal?: AbortSignal
) => {
  return customInstance<InterventionResultResponse[]>({
    url: `/operations/own/customer/platform/customer/check/audit/records/${customerUid}`,
    method: 'GET',
    signal,
  });
};

export const getGetPlatformCustomerCheckAuditRecordQueryKey = (
  customerUid: string
) => {
  return [
    `/operations/own/customer/platform/customer/check/audit/records/${customerUid}`,
  ] as const;
};

export const getGetPlatformCustomerCheckAuditRecordQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlatformCustomerCheckAuditRecord>>,
  TError = unknown
>(
  customerUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformCustomerCheckAuditRecord>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPlatformCustomerCheckAuditRecordQueryKey(customerUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlatformCustomerCheckAuditRecord>>
  > = ({ signal }) => getPlatformCustomerCheckAuditRecord(customerUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!customerUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlatformCustomerCheckAuditRecord>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlatformCustomerCheckAuditRecordQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlatformCustomerCheckAuditRecord>>
>;
export type GetPlatformCustomerCheckAuditRecordQueryError = unknown;

export const useGetPlatformCustomerCheckAuditRecord = <
  TData = Awaited<ReturnType<typeof getPlatformCustomerCheckAuditRecord>>,
  TError = unknown
>(
  customerUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformCustomerCheckAuditRecord>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlatformCustomerCheckAuditRecordQueryOptions(
    customerUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const startComplianceCheckForCustomer = (customerUid: string) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/customer/check/${customerUid}`,
    method: 'POST',
  });
};

export const getStartComplianceCheckForCustomerMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startComplianceCheckForCustomer>>,
    TError,
    { customerUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof startComplianceCheckForCustomer>>,
  TError,
  { customerUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof startComplianceCheckForCustomer>>,
    { customerUid: string }
  > = (props) => {
    const { customerUid } = props ?? {};

    return startComplianceCheckForCustomer(customerUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartComplianceCheckForCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof startComplianceCheckForCustomer>>
>;

export type StartComplianceCheckForCustomerMutationError = unknown;

export const useStartComplianceCheckForCustomer = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startComplianceCheckForCustomer>>,
    TError,
    { customerUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof startComplianceCheckForCustomer>>,
  TError,
  { customerUid: string },
  TContext
> => {
  const mutationOptions =
    getStartComplianceCheckForCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listClientDetailsForPerson = (
  personUid: string,
  signal?: AbortSignal
) => {
  return customInstance<ClientDetailsResponse>({
    url: `/operations/own/customer/platform/customer/client/details/person/${personUid}`,
    method: 'GET',
    signal,
  });
};

export const getListClientDetailsForPersonQueryKey = (personUid: string) => {
  return [
    `/operations/own/customer/platform/customer/client/details/person/${personUid}`,
  ] as const;
};

export const getListClientDetailsForPersonQueryOptions = <
  TData = Awaited<ReturnType<typeof listClientDetailsForPerson>>,
  TError = unknown
>(
  personUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listClientDetailsForPerson>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListClientDetailsForPersonQueryKey(personUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listClientDetailsForPerson>>
  > = ({ signal }) => listClientDetailsForPerson(personUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!personUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof listClientDetailsForPerson>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListClientDetailsForPersonQueryResult = NonNullable<
  Awaited<ReturnType<typeof listClientDetailsForPerson>>
>;
export type ListClientDetailsForPersonQueryError = unknown;

export const useListClientDetailsForPerson = <
  TData = Awaited<ReturnType<typeof listClientDetailsForPerson>>,
  TError = unknown
>(
  personUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listClientDetailsForPerson>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListClientDetailsForPersonQueryOptions(
    personUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listClientDetails = (
  customerUid: string,
  signal?: AbortSignal
) => {
  return customInstance<ClientDetailsResponse>({
    url: `/operations/own/customer/platform/customer/client/details/${customerUid}`,
    method: 'GET',
    signal,
  });
};

export const getListClientDetailsQueryKey = (customerUid: string) => {
  return [
    `/operations/own/customer/platform/customer/client/details/${customerUid}`,
  ] as const;
};

export const getListClientDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof listClientDetails>>,
  TError = unknown
>(
  customerUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listClientDetails>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListClientDetailsQueryKey(customerUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listClientDetails>>> =
    ({ signal }) => listClientDetails(customerUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!customerUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof listClientDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListClientDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listClientDetails>>
>;
export type ListClientDetailsQueryError = unknown;

export const useListClientDetails = <
  TData = Awaited<ReturnType<typeof listClientDetails>>,
  TError = unknown
>(
  customerUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listClientDetails>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListClientDetailsQueryOptions(customerUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listPlatformCustomersDetails = (
  params?: ListPlatformCustomersDetailsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPagePlatformCustomerScreenResponse>({
    url: `/operations/own/customer/platform/customer/details`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListPlatformCustomersDetailsQueryKey = (
  params?: ListPlatformCustomersDetailsParams
) => {
  return [
    `/operations/own/customer/platform/customer/details`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListPlatformCustomersDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof listPlatformCustomersDetails>>,
  TError = unknown
>(
  params?: ListPlatformCustomersDetailsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPlatformCustomersDetails>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListPlatformCustomersDetailsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listPlatformCustomersDetails>>
  > = ({ signal }) => listPlatformCustomersDetails(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPlatformCustomersDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPlatformCustomersDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPlatformCustomersDetails>>
>;
export type ListPlatformCustomersDetailsQueryError = unknown;

export const useListPlatformCustomersDetails = <
  TData = Awaited<ReturnType<typeof listPlatformCustomersDetails>>,
  TError = unknown
>(
  params?: ListPlatformCustomersDetailsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPlatformCustomersDetails>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPlatformCustomersDetailsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listPlatformCustomerDetailsMinimal = (
  params?: ListPlatformCustomerDetailsMinimalParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPagePlatformCustomerScreenReducedResponse>({
    url: `/operations/own/customer/platform/customer/details/minimal`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListPlatformCustomerDetailsMinimalQueryKey = (
  params?: ListPlatformCustomerDetailsMinimalParams
) => {
  return [
    `/operations/own/customer/platform/customer/details/minimal`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListPlatformCustomerDetailsMinimalQueryOptions = <
  TData = Awaited<ReturnType<typeof listPlatformCustomerDetailsMinimal>>,
  TError = unknown
>(
  params?: ListPlatformCustomerDetailsMinimalParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPlatformCustomerDetailsMinimal>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListPlatformCustomerDetailsMinimalQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listPlatformCustomerDetailsMinimal>>
  > = ({ signal }) => listPlatformCustomerDetailsMinimal(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPlatformCustomerDetailsMinimal>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPlatformCustomerDetailsMinimalQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPlatformCustomerDetailsMinimal>>
>;
export type ListPlatformCustomerDetailsMinimalQueryError = unknown;

export const useListPlatformCustomerDetailsMinimal = <
  TData = Awaited<ReturnType<typeof listPlatformCustomerDetailsMinimal>>,
  TError = unknown
>(
  params?: ListPlatformCustomerDetailsMinimalParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPlatformCustomerDetailsMinimal>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPlatformCustomerDetailsMinimalQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchAllPendingCustomerKycDocuments = (signal?: AbortSignal) => {
  return customInstance<FetchDocumentsResponse[]>({
    url: `/operations/own/customer/platform/customer/intervention/documents`,
    method: 'GET',
    signal,
  });
};

export const getFetchAllPendingCustomerKycDocumentsQueryKey = () => {
  return [
    `/operations/own/customer/platform/customer/intervention/documents`,
  ] as const;
};

export const getFetchAllPendingCustomerKycDocumentsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchAllPendingCustomerKycDocuments>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof fetchAllPendingCustomerKycDocuments>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchAllPendingCustomerKycDocumentsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchAllPendingCustomerKycDocuments>>
  > = ({ signal }) => fetchAllPendingCustomerKycDocuments(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchAllPendingCustomerKycDocuments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchAllPendingCustomerKycDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchAllPendingCustomerKycDocuments>>
>;
export type FetchAllPendingCustomerKycDocumentsQueryError = unknown;

export const useFetchAllPendingCustomerKycDocuments = <
  TData = Awaited<ReturnType<typeof fetchAllPendingCustomerKycDocuments>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof fetchAllPendingCustomerKycDocuments>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getFetchAllPendingCustomerKycDocumentsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchAllDocumentsForAggregate = (
  aggregateUid: string,
  signal?: AbortSignal
) => {
  return customInstance<FetchDocumentsResponse[]>({
    url: `/operations/own/customer/platform/customer/intervention/documents/aggregate/${aggregateUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchAllDocumentsForAggregateQueryKey = (
  aggregateUid: string
) => {
  return [
    `/operations/own/customer/platform/customer/intervention/documents/aggregate/${aggregateUid}`,
  ] as const;
};

export const getFetchAllDocumentsForAggregateQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchAllDocumentsForAggregate>>,
  TError = unknown
>(
  aggregateUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchAllDocumentsForAggregate>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchAllDocumentsForAggregateQueryKey(aggregateUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchAllDocumentsForAggregate>>
  > = ({ signal }) => fetchAllDocumentsForAggregate(aggregateUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!aggregateUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchAllDocumentsForAggregate>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchAllDocumentsForAggregateQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchAllDocumentsForAggregate>>
>;
export type FetchAllDocumentsForAggregateQueryError = unknown;

export const useFetchAllDocumentsForAggregate = <
  TData = Awaited<ReturnType<typeof fetchAllDocumentsForAggregate>>,
  TError = unknown
>(
  aggregateUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchAllDocumentsForAggregate>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchAllDocumentsForAggregateQueryOptions(
    aggregateUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assignDocumentsToCustomer = (
  assignDocumentsRequest: AssignDocumentsRequest
) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/customer/intervention/documents/assign`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: assignDocumentsRequest,
  });
};

export const getAssignDocumentsToCustomerMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assignDocumentsToCustomer>>,
    TError,
    { data: AssignDocumentsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assignDocumentsToCustomer>>,
  TError,
  { data: AssignDocumentsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assignDocumentsToCustomer>>,
    { data: AssignDocumentsRequest }
  > = (props) => {
    const { data } = props ?? {};

    return assignDocumentsToCustomer(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignDocumentsToCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof assignDocumentsToCustomer>>
>;
export type AssignDocumentsToCustomerMutationBody = AssignDocumentsRequest;
export type AssignDocumentsToCustomerMutationError = unknown;

export const useAssignDocumentsToCustomer = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assignDocumentsToCustomer>>,
    TError,
    { data: AssignDocumentsRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof assignDocumentsToCustomer>>,
  TError,
  { data: AssignDocumentsRequest },
  TContext
> => {
  const mutationOptions = getAssignDocumentsToCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchDocuments = (
  params?: FetchDocumentsParams,
  signal?: AbortSignal
) => {
  return customInstance<FetchDocumentsResponse[]>({
    url: `/operations/own/customer/platform/customer/intervention/documents/documents`,
    method: 'GET',
    params,
    signal,
  });
};

export const getFetchDocumentsQueryKey = (params?: FetchDocumentsParams) => {
  return [
    `/operations/own/customer/platform/customer/intervention/documents/documents`,
    ...(params ? [params] : []),
  ] as const;
};

export const getFetchDocumentsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchDocuments>>,
  TError = unknown
>(
  params?: FetchDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchDocuments>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFetchDocumentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchDocuments>>> = ({
    signal,
  }) => fetchDocuments(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchDocuments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchDocuments>>
>;
export type FetchDocumentsQueryError = unknown;

export const useFetchDocuments = <
  TData = Awaited<ReturnType<typeof fetchDocuments>>,
  TError = unknown
>(
  params?: FetchDocumentsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchDocuments>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchDocumentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const generatePresignedUrlForDocument = (
  documentUid: string,
  signal?: AbortSignal
) => {
  return customInstance<InternalDocumentUrlResponse>({
    url: `/operations/own/customer/platform/customer/intervention/documents/presigned/${documentUid}`,
    method: 'GET',
    signal,
  });
};

export const getGeneratePresignedUrlForDocumentQueryKey = (
  documentUid: string
) => {
  return [
    `/operations/own/customer/platform/customer/intervention/documents/presigned/${documentUid}`,
  ] as const;
};

export const getGeneratePresignedUrlForDocumentQueryOptions = <
  TData = Awaited<ReturnType<typeof generatePresignedUrlForDocument>>,
  TError = unknown
>(
  documentUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof generatePresignedUrlForDocument>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGeneratePresignedUrlForDocumentQueryKey(documentUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof generatePresignedUrlForDocument>>
  > = ({ signal }) => generatePresignedUrlForDocument(documentUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!documentUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof generatePresignedUrlForDocument>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GeneratePresignedUrlForDocumentQueryResult = NonNullable<
  Awaited<ReturnType<typeof generatePresignedUrlForDocument>>
>;
export type GeneratePresignedUrlForDocumentQueryError = unknown;

export const useGeneratePresignedUrlForDocument = <
  TData = Awaited<ReturnType<typeof generatePresignedUrlForDocument>>,
  TError = unknown
>(
  documentUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof generatePresignedUrlForDocument>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGeneratePresignedUrlForDocumentQueryOptions(
    documentUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const rejectAllCustomerDocuments = (aggregateUid: string) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/customer/intervention/documents/${aggregateUid}`,
    method: 'DELETE',
  });
};

export const getRejectAllCustomerDocumentsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectAllCustomerDocuments>>,
    TError,
    { aggregateUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rejectAllCustomerDocuments>>,
  TError,
  { aggregateUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rejectAllCustomerDocuments>>,
    { aggregateUid: string }
  > = (props) => {
    const { aggregateUid } = props ?? {};

    return rejectAllCustomerDocuments(aggregateUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type RejectAllCustomerDocumentsMutationResult = NonNullable<
  Awaited<ReturnType<typeof rejectAllCustomerDocuments>>
>;

export type RejectAllCustomerDocumentsMutationError = unknown;

export const useRejectAllCustomerDocuments = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectAllCustomerDocuments>>,
    TError,
    { aggregateUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof rejectAllCustomerDocuments>>,
  TError,
  { aggregateUid: string },
  TContext
> => {
  const mutationOptions = getRejectAllCustomerDocumentsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const reviewCustomerKycDocument = (
  documentUid: string,
  documentReviewRequest: DocumentReviewRequest
) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/customer/intervention/documents/${documentUid}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: documentReviewRequest,
  });
};

export const getReviewCustomerKycDocumentMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reviewCustomerKycDocument>>,
    TError,
    { documentUid: string; data: DocumentReviewRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof reviewCustomerKycDocument>>,
  TError,
  { documentUid: string; data: DocumentReviewRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reviewCustomerKycDocument>>,
    { documentUid: string; data: DocumentReviewRequest }
  > = (props) => {
    const { documentUid, data } = props ?? {};

    return reviewCustomerKycDocument(documentUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ReviewCustomerKycDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof reviewCustomerKycDocument>>
>;
export type ReviewCustomerKycDocumentMutationBody = DocumentReviewRequest;
export type ReviewCustomerKycDocumentMutationError = unknown;

export const useReviewCustomerKycDocument = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reviewCustomerKycDocument>>,
    TError,
    { documentUid: string; data: DocumentReviewRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof reviewCustomerKycDocument>>,
  TError,
  { documentUid: string; data: DocumentReviewRequest },
  TContext
> => {
  const mutationOptions = getReviewCustomerKycDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const sendFsmEvent = (externalFSMEvent: ExternalFSMEvent) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/customer/intervention/fsmEvent`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: externalFSMEvent,
  });
};

export const getSendFsmEventMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendFsmEvent>>,
    TError,
    { data: ExternalFSMEvent },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendFsmEvent>>,
  TError,
  { data: ExternalFSMEvent },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendFsmEvent>>,
    { data: ExternalFSMEvent }
  > = (props) => {
    const { data } = props ?? {};

    return sendFsmEvent(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendFsmEventMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendFsmEvent>>
>;
export type SendFsmEventMutationBody = ExternalFSMEvent;
export type SendFsmEventMutationError = unknown;

export const useSendFsmEvent = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendFsmEvent>>,
    TError,
    { data: ExternalFSMEvent },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendFsmEvent>>,
  TError,
  { data: ExternalFSMEvent },
  TContext
> => {
  const mutationOptions = getSendFsmEventMutationOptions(options);

  return useMutation(mutationOptions);
};
export const requestCustomerKycInformation = (
  customerUid: string,
  kycDocumentInformationRequest: KycDocumentInformationRequest
) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/customer/intervention/request/${customerUid}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: kycDocumentInformationRequest,
  });
};

export const getRequestCustomerKycInformationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestCustomerKycInformation>>,
    TError,
    { customerUid: string; data: KycDocumentInformationRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof requestCustomerKycInformation>>,
  TError,
  { customerUid: string; data: KycDocumentInformationRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof requestCustomerKycInformation>>,
    { customerUid: string; data: KycDocumentInformationRequest }
  > = (props) => {
    const { customerUid, data } = props ?? {};

    return requestCustomerKycInformation(customerUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RequestCustomerKycInformationMutationResult = NonNullable<
  Awaited<ReturnType<typeof requestCustomerKycInformation>>
>;
export type RequestCustomerKycInformationMutationBody =
  KycDocumentInformationRequest;
export type RequestCustomerKycInformationMutationError = unknown;

export const useRequestCustomerKycInformation = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof requestCustomerKycInformation>>,
    TError,
    { customerUid: string; data: KycDocumentInformationRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof requestCustomerKycInformation>>,
  TError,
  { customerUid: string; data: KycDocumentInformationRequest },
  TContext
> => {
  const mutationOptions =
    getRequestCustomerKycInformationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updatePlatformCustomerRiskResult = (
  customerUid: string,
  riskResult: RiskResult
) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/customer/intervention/riskResult/${customerUid}/result/${riskResult}`,
    method: 'PUT',
  });
};

export const getUpdatePlatformCustomerRiskResultMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlatformCustomerRiskResult>>,
    TError,
    { customerUid: string; riskResult: RiskResult },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePlatformCustomerRiskResult>>,
  TError,
  { customerUid: string; riskResult: RiskResult },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePlatformCustomerRiskResult>>,
    { customerUid: string; riskResult: RiskResult }
  > = (props) => {
    const { customerUid, riskResult } = props ?? {};

    return updatePlatformCustomerRiskResult(customerUid, riskResult);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePlatformCustomerRiskResultMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePlatformCustomerRiskResult>>
>;

export type UpdatePlatformCustomerRiskResultMutationError = unknown;

export const useUpdatePlatformCustomerRiskResult = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlatformCustomerRiskResult>>,
    TError,
    { customerUid: string; riskResult: RiskResult },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePlatformCustomerRiskResult>>,
  TError,
  { customerUid: string; riskResult: RiskResult },
  TContext
> => {
  const mutationOptions =
    getUpdatePlatformCustomerRiskResultMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updatePlatformCustomerVerificationStatus = (
  customerUid: string,
  updatePlatformCustomerVerificationStatusRequest: UpdatePlatformCustomerVerificationStatusRequest
) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/customer/intervention/status/${customerUid}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updatePlatformCustomerVerificationStatusRequest,
  });
};

export const getUpdatePlatformCustomerVerificationStatusMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlatformCustomerVerificationStatus>>,
    TError,
    {
      customerUid: string;
      data: UpdatePlatformCustomerVerificationStatusRequest;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePlatformCustomerVerificationStatus>>,
  TError,
  {
    customerUid: string;
    data: UpdatePlatformCustomerVerificationStatusRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePlatformCustomerVerificationStatus>>,
    {
      customerUid: string;
      data: UpdatePlatformCustomerVerificationStatusRequest;
    }
  > = (props) => {
    const { customerUid, data } = props ?? {};

    return updatePlatformCustomerVerificationStatus(customerUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePlatformCustomerVerificationStatusMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof updatePlatformCustomerVerificationStatus>>
  >;
export type UpdatePlatformCustomerVerificationStatusMutationBody =
  UpdatePlatformCustomerVerificationStatusRequest;
export type UpdatePlatformCustomerVerificationStatusMutationError = unknown;

export const useUpdatePlatformCustomerVerificationStatus = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlatformCustomerVerificationStatus>>,
    TError,
    {
      customerUid: string;
      data: UpdatePlatformCustomerVerificationStatusRequest;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePlatformCustomerVerificationStatus>>,
  TError,
  {
    customerUid: string;
    data: UpdatePlatformCustomerVerificationStatusRequest;
  },
  TContext
> => {
  const mutationOptions =
    getUpdatePlatformCustomerVerificationStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listPendingCustomerNominatedAccountChanges = (
  params?: ListPendingCustomerNominatedAccountChangesParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageCustomerNominatedAccountChangeResponse>({
    url: `/operations/own/customer/platform/customer/nominatedAccountChanges`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListPendingCustomerNominatedAccountChangesQueryKey = (
  params?: ListPendingCustomerNominatedAccountChangesParams
) => {
  return [
    `/operations/own/customer/platform/customer/nominatedAccountChanges`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListPendingCustomerNominatedAccountChangesQueryOptions = <
  TData = Awaited<
    ReturnType<typeof listPendingCustomerNominatedAccountChanges>
  >,
  TError = unknown
>(
  params?: ListPendingCustomerNominatedAccountChangesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPendingCustomerNominatedAccountChanges>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListPendingCustomerNominatedAccountChangesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listPendingCustomerNominatedAccountChanges>>
  > = ({ signal }) =>
    listPendingCustomerNominatedAccountChanges(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPendingCustomerNominatedAccountChanges>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPendingCustomerNominatedAccountChangesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPendingCustomerNominatedAccountChanges>>
>;
export type ListPendingCustomerNominatedAccountChangesQueryError = unknown;

export const useListPendingCustomerNominatedAccountChanges = <
  TData = Awaited<
    ReturnType<typeof listPendingCustomerNominatedAccountChanges>
  >,
  TError = unknown
>(
  params?: ListPendingCustomerNominatedAccountChangesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPendingCustomerNominatedAccountChanges>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getListPendingCustomerNominatedAccountChangesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const acceptAccountChangeProposal = (proposalUid: string) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/customer/nominatedAccountChanges/${proposalUid}/accept`,
    method: 'PUT',
  });
};

export const getAcceptAccountChangeProposalMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptAccountChangeProposal>>,
    TError,
    { proposalUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof acceptAccountChangeProposal>>,
  TError,
  { proposalUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof acceptAccountChangeProposal>>,
    { proposalUid: string }
  > = (props) => {
    const { proposalUid } = props ?? {};

    return acceptAccountChangeProposal(proposalUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcceptAccountChangeProposalMutationResult = NonNullable<
  Awaited<ReturnType<typeof acceptAccountChangeProposal>>
>;

export type AcceptAccountChangeProposalMutationError = unknown;

export const useAcceptAccountChangeProposal = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptAccountChangeProposal>>,
    TError,
    { proposalUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof acceptAccountChangeProposal>>,
  TError,
  { proposalUid: string },
  TContext
> => {
  const mutationOptions =
    getAcceptAccountChangeProposalMutationOptions(options);

  return useMutation(mutationOptions);
};
export const rejectAccountNameProposal = (proposalUid: string) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/customer/nominatedAccountChanges/${proposalUid}/reject`,
    method: 'PUT',
  });
};

export const getRejectAccountNameProposalMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectAccountNameProposal>>,
    TError,
    { proposalUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rejectAccountNameProposal>>,
  TError,
  { proposalUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rejectAccountNameProposal>>,
    { proposalUid: string }
  > = (props) => {
    const { proposalUid } = props ?? {};

    return rejectAccountNameProposal(proposalUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type RejectAccountNameProposalMutationResult = NonNullable<
  Awaited<ReturnType<typeof rejectAccountNameProposal>>
>;

export type RejectAccountNameProposalMutationError = unknown;

export const useRejectAccountNameProposal = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectAccountNameProposal>>,
    TError,
    { proposalUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof rejectAccountNameProposal>>,
  TError,
  { proposalUid: string },
  TContext
> => {
  const mutationOptions = getRejectAccountNameProposalMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchPeople = (
  params?: FetchPeopleParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPagePlatformPersonPaginatedRecord>({
    url: `/operations/own/customer/platform/customer/people`,
    method: 'GET',
    params,
    signal,
  });
};

export const getFetchPeopleQueryKey = (params?: FetchPeopleParams) => {
  return [
    `/operations/own/customer/platform/customer/people`,
    ...(params ? [params] : []),
  ] as const;
};

export const getFetchPeopleQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchPeople>>,
  TError = unknown
>(
  params?: FetchPeopleParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPeople>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFetchPeopleQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchPeople>>> = ({
    signal,
  }) => fetchPeople(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchPeople>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchPeopleQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchPeople>>
>;
export type FetchPeopleQueryError = unknown;

export const useFetchPeople = <
  TData = Awaited<ReturnType<typeof fetchPeople>>,
  TError = unknown
>(
  params?: FetchPeopleParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPeople>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchPeopleQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const assignDocumentsToPerson = (
  assignDocumentsRequest: AssignDocumentsRequest
) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/customer/person/intervention/documents/assign`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: assignDocumentsRequest,
  });
};

export const getAssignDocumentsToPersonMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assignDocumentsToPerson>>,
    TError,
    { data: AssignDocumentsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof assignDocumentsToPerson>>,
  TError,
  { data: AssignDocumentsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assignDocumentsToPerson>>,
    { data: AssignDocumentsRequest }
  > = (props) => {
    const { data } = props ?? {};

    return assignDocumentsToPerson(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignDocumentsToPersonMutationResult = NonNullable<
  Awaited<ReturnType<typeof assignDocumentsToPerson>>
>;
export type AssignDocumentsToPersonMutationBody = AssignDocumentsRequest;
export type AssignDocumentsToPersonMutationError = unknown;

export const useAssignDocumentsToPerson = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assignDocumentsToPerson>>,
    TError,
    { data: AssignDocumentsRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof assignDocumentsToPerson>>,
  TError,
  { data: AssignDocumentsRequest },
  TContext
> => {
  const mutationOptions = getAssignDocumentsToPersonMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listCustomersRequiringIntervention = (signal?: AbortSignal) => {
  return customInstance<
    SavingsExchangeInterventionRequiredPersonIntervention[]
  >({
    url: `/operations/own/customer/platform/customer/verification/intervention`,
    method: 'GET',
    signal,
  });
};

export const getListCustomersRequiringInterventionQueryKey = () => {
  return [
    `/operations/own/customer/platform/customer/verification/intervention`,
  ] as const;
};

export const getListCustomersRequiringInterventionQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomersRequiringIntervention>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listCustomersRequiringIntervention>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListCustomersRequiringInterventionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listCustomersRequiringIntervention>>
  > = ({ signal }) => listCustomersRequiringIntervention(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomersRequiringIntervention>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomersRequiringInterventionQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomersRequiringIntervention>>
>;
export type ListCustomersRequiringInterventionQueryError = unknown;

export const useListCustomersRequiringIntervention = <
  TData = Awaited<ReturnType<typeof listCustomersRequiringIntervention>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listCustomersRequiringIntervention>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getListCustomersRequiringInterventionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const performIntervention = (
  savingsExchangeInterventionReceivedPersonIntervention: SavingsExchangeInterventionReceivedPersonIntervention
) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/customer/verification/intervention`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: savingsExchangeInterventionReceivedPersonIntervention,
  });
};

export const getPerformInterventionMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof performIntervention>>,
    TError,
    { data: SavingsExchangeInterventionReceivedPersonIntervention },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof performIntervention>>,
  TError,
  { data: SavingsExchangeInterventionReceivedPersonIntervention },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof performIntervention>>,
    { data: SavingsExchangeInterventionReceivedPersonIntervention }
  > = (props) => {
    const { data } = props ?? {};

    return performIntervention(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PerformInterventionMutationResult = NonNullable<
  Awaited<ReturnType<typeof performIntervention>>
>;
export type PerformInterventionMutationBody =
  SavingsExchangeInterventionReceivedPersonIntervention;
export type PerformInterventionMutationError = unknown;

export const usePerformIntervention = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof performIntervention>>,
    TError,
    { data: SavingsExchangeInterventionReceivedPersonIntervention },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof performIntervention>>,
  TError,
  { data: SavingsExchangeInterventionReceivedPersonIntervention },
  TContext
> => {
  const mutationOptions = getPerformInterventionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listCustomersByPlatform = (
  platformUid: string,
  params?: ListCustomersByPlatformParams,
  signal?: AbortSignal
) => {
  return customInstance<PlatformCustomerResponse[]>({
    url: `/operations/own/customer/platform/${platformUid}`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListCustomersByPlatformQueryKey = (
  platformUid: string,
  params?: ListCustomersByPlatformParams
) => {
  return [
    `/operations/own/customer/platform/${platformUid}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListCustomersByPlatformQueryOptions = <
  TData = Awaited<ReturnType<typeof listCustomersByPlatform>>,
  TError = unknown
>(
  platformUid: string,
  params?: ListCustomersByPlatformParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomersByPlatform>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListCustomersByPlatformQueryKey(platformUid, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listCustomersByPlatform>>
  > = ({ signal }) => listCustomersByPlatform(platformUid, params, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof listCustomersByPlatform>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListCustomersByPlatformQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomersByPlatform>>
>;
export type ListCustomersByPlatformQueryError = unknown;

export const useListCustomersByPlatform = <
  TData = Awaited<ReturnType<typeof listCustomersByPlatform>>,
  TError = unknown
>(
  platformUid: string,
  params?: ListCustomersByPlatformParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCustomersByPlatform>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListCustomersByPlatformQueryOptions(
    platformUid,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const proposeCustomerAccountDetailChange = (
  platformUid: string,
  customerUid: string,
  nominatedAccountRequest: NominatedAccountRequest
) => {
  return customInstance<void>({
    url: `/operations/own/customer/platform/${platformUid}/${customerUid}/update-nom-account`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: nominatedAccountRequest,
  });
};

export const getProposeCustomerAccountDetailChangeMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeCustomerAccountDetailChange>>,
    TError,
    { platformUid: string; customerUid: string; data: NominatedAccountRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof proposeCustomerAccountDetailChange>>,
  TError,
  { platformUid: string; customerUid: string; data: NominatedAccountRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposeCustomerAccountDetailChange>>,
    { platformUid: string; customerUid: string; data: NominatedAccountRequest }
  > = (props) => {
    const { platformUid, customerUid, data } = props ?? {};

    return proposeCustomerAccountDetailChange(platformUid, customerUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProposeCustomerAccountDetailChangeMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposeCustomerAccountDetailChange>>
>;
export type ProposeCustomerAccountDetailChangeMutationBody =
  NominatedAccountRequest;
export type ProposeCustomerAccountDetailChangeMutationError = unknown;

export const useProposeCustomerAccountDetailChange = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeCustomerAccountDetailChange>>,
    TError,
    { platformUid: string; customerUid: string; data: NominatedAccountRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof proposeCustomerAccountDetailChange>>,
  TError,
  { platformUid: string; customerUid: string; data: NominatedAccountRequest },
  TContext
> => {
  const mutationOptions =
    getProposeCustomerAccountDetailChangeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchCustomersRequiringIntervention = (
  params: FetchCustomersRequiringInterventionParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageComplianceInterventionPaginatedRecord>({
    url: `/operations/own/customer/v2/platform/customer/verification/intervention`,
    method: 'GET',
    params,
    signal,
  });
};

export const getFetchCustomersRequiringInterventionQueryKey = (
  params: FetchCustomersRequiringInterventionParams
) => {
  return [
    `/operations/own/customer/v2/platform/customer/verification/intervention`,
    ...(params ? [params] : []),
  ] as const;
};

export const getFetchCustomersRequiringInterventionQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchCustomersRequiringIntervention>>,
  TError = unknown
>(
  params: FetchCustomersRequiringInterventionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchCustomersRequiringIntervention>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchCustomersRequiringInterventionQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchCustomersRequiringIntervention>>
  > = ({ signal }) => fetchCustomersRequiringIntervention(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchCustomersRequiringIntervention>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchCustomersRequiringInterventionQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchCustomersRequiringIntervention>>
>;
export type FetchCustomersRequiringInterventionQueryError = unknown;

export const useFetchCustomersRequiringIntervention = <
  TData = Awaited<ReturnType<typeof fetchCustomersRequiringIntervention>>,
  TError = unknown
>(
  params: FetchCustomersRequiringInterventionParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchCustomersRequiringIntervention>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchCustomersRequiringInterventionQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
