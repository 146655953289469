/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type SubProductType = typeof SubProductType[keyof typeof SubProductType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubProductType = {
  INSTANT: 'INSTANT',
  NOTICE: 'NOTICE',
  TERM: 'TERM',
} as const;
